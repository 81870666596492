<template>

  <div class="ma-2">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Transactions ma-2">
      <h3>Активные оплаты</h3>
    </div>


    <div>
      <v-data-table
          dense
          :items="items"
          :headers="headers"
          class="elevation-1 mt-3"
          sort-by="id"
          sort-desc
          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',

                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':
[
  50,
  100,
  200,
  -1
]
          }"
      >

        <template v-slot:item.id="{ item }"> {{ item.id }}</template>
        <template v-slot:item.nickname="{ item }"> {{ item.user.nickname }}</template>
        <template v-slot:item.currency_amount="{ item }"> {{ item.currency_amount }} {{ item.currency.short_title }}
        </template>

        <template v-slot:item.created_at="{ item }">
          <DateTimeCell :value="item.created_at"></DateTimeCell>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <DateTimeCell :value="item.updated_at"></DateTimeCell>
        </template>
        <template v-slot:item.hired_at="{ item }">
          <DateTimeCell v-if="item.hired_at" :value="item.hired_at"></DateTimeCell>
        </template>

        <!--template v-slot:item.payment_link="{ item }"><a :href="item.payment_link">ссылка</a></template-->

        <template v-slot:item.payment_link="{ item }">

          <v-btn    class="blue"  dense text target="_blank"
                  :href="item.payment_link">ссылка
          </v-btn>
        </template>

        <template v-slot:item.status="{ item }">
          <StatusCell :item="item"/>
        </template>



        <template v-slot:item.actions="{ item }">

          <v-btn v-if="item.status === 'В ожидании'" class="green mr-1  mb-1"    dense text   @click="take(item)">В работу </v-btn>

          <TextInputDialog  v-if="item.status === 'В ожидании' || item.status === 'В процессе'" buttonclass="red" buttontext="Отмена" caption="Причина" :tag="item" v-on:ok="del"></TextInputDialog>
          <!--v-btn dense class="red mr-1 mb-1" text @click="del(item)">Отмена</v-btn-->
          <ImagePickerDialog  v-if="item.status === 'В процессе'"  caption="Успех" v-bind:tag="item"  v-on:ok="good"  />


          <!--v-btn dense class="green mr-1 mb-1" text @click="good(item)">Успех</v-btn-->


        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
//
import gql from "graphql-tag";
import {getHeaders} from "../store";
import StatusCell from "../components/controls/StatusCell.vue";
import DateTimeCell from "../components/controls/DateTimeCell.vue";
//import FinanceCell from "../components/controls/FinanceCell.vue";

const RestApiUrl = process.env.VUE_APP_EXCHANGE_API + "/payment_via_links"// payment_via_links/{payment_via_link}/hire/{token}
import {fetcher} from "../store";
import Snackbar from "../components/controls/Snackbar.vue";
import TextInputDialog from "../components/dialogs/TextInputDialog.vue";
import ImagePickerDialog from "../components/dialogs/ImagePickerDialog.vue";


export default {
  name: 'PaymentLink',

  components: {
    ImagePickerDialog,
    TextInputDialog,

    //FinanceCell,
    DateTimeCell, StatusCell, Snackbar
  },//DatePeriodPicker

  apollo: {

    $subscribe: {
      transactions: {
        query: gql`
subscription MyQuery {
  payment_via_links(where: {status: {_in: ["В ожидании", "В работе", "В процессе"]}}) {
    id
    balance_after
    balance_before
    cancellation_message
    comment
    course
    created_at
    currency_amount
    currency_id
    hired_at
    payment_link
    proof
    status
    updated_at
    usdt_amount
    user_id
    currency {
      title
      short_title
    }
    user {
      nickname
    }
  }
}


`,

        variables() {

        },

        result({data}) {
          this.items = data.payment_via_links;
        },

      },
    },
  },

  data: () => ({

    chatid: "",

    filterByDatePeriod: false,

    items: [],


    /*

     ник пользователя - users. join users.id = payments_via_links.user_id

      - payments_via_links.currency_amount (название валюты currencies.short_title join currencies.id = payments_via_links.currency_id)


    - payment_via_links.

   Комментарий отмены - payment_via_links.cancellation_message
     */


    /*


    ID
    ник пользователя
    Время создания
    Статус
    Сумму к оплате (фиат) + валюту (EUR/RUB)
    Сумму списаных USDT с баланса
    Ссылку на оплату
    баланс до
    баланс после
    курс EUR / USDT или RUB / USDT на момент создания оплаты
    Комментарий к оплате
    Кнопки
        Пока оплата “в ожидании” - взять в работу / отменить. Взять в работу - переводит в статус “в процессе”. При отмене - обязательно ввести комментарий.
        После того как оплата взята в работу (статус “в процессе”) - завершить / отменить - При отмене - обязательно ввести комментарий. При завершении успехом - обязательно прикрепить файл (картинку jpg / png).


     */


    headers: [
      {text: 'ID', value: 'id'},
      {text: 'Статус', value: 'status'},
      {text: 'Ник', value: 'nickname'},
      {text: 'Время создания', value: 'created_at'},
      //{text: 'Время усп\\отм', value: 'updated_at'},
      //{text: 'Время вз в раб', value: 'hired_at'},
      {text: 'Сумма к оплате', value: 'currency_amount'},
      {text: 'Списаных USDT', value: 'usdt_amount'},
      {text: 'Ссылка на оплату', value: 'payment_link'},
      {text: 'Комментарий оплаты', value: 'comment'},
      {text: 'Баланс до', value: 'balance_before'},
      {text: 'Баланс после', value: 'balance_after'},
      {text: 'Курс', value: 'course'},
      //{text: 'Чек', value: 'proof'},

      {text: 'Действия', value: 'actions'},


    ],
  }),


  methods: {

    spam(item) {
      return item.status === 'proof' && (new Date(item.updatedAt) - new Date(item.createdAt)) < 10000
    },

    get$ByCountry2(item) {

      return this.get$ByCountry(item.bank.country)

    },

    get$ByCountry(country) {

      switch (country) {
        case "ru" :
          return "₽"
        case "en" :
          return "$"
        case "tr" :
          return "₺"
        case "kz" :
          return "₸"
        case "uz" :
          return "UZS"

        default:
          return "def$"
      }

    },

    getCountry() {
      return this.$store.state.user.country
    },

    async directRefill(user, card, sum) {
      /* POST
      /api/admin/transaction
     merchantId
     cardId
     amount */

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(
            {
              merchantId: +(user.id),
              cardId: +(card.id),
              amount: +sum
            })
      };

      let url = `${RestApiUrl}`

      fetcher(this.dosnackbar, url, requestOptions)


    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },

    onPerodChanged(val) {
      //  console.log('onPerodChanged', val)
      this.from = val.from
      this.to = val.to
    },

    usdt(item) {
      //  console.log(item.offer)
      return (item.amount / item.offer.rate - (item.offer.fee / 100 * (item.amount / item.offer.rate))) || 0
    },

    async card(id, card) {
      // Выдать карту
      // app.patch("/api/admin/transaction/:id/offer"
      // req.body.cardId - id карты (int)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({cardId: card.id})
      };

      let url = `${RestApiUrl}/${id}/offer`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async amount(id, amount) {
      //Изменить сумму
      //app.patch("/api/admin/transaction/:id/amount"
      //req.body.amount - сумма в рублях (float)

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({amount: amount})
      };

      let url = `${RestApiUrl}/${id}/amount`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async take(item) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
      };

      //patch payment_via_links/{payment_via_link}/hire/{token}
      let url = `${RestApiUrl}/${item.id}/hire/1`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async del(item,text) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({cancellation_message:text})
      };

      //delete payment_via_links/{payment_via_link}/{token}
      let url = `${RestApiUrl}/${item.id}/1`

      fetcher(this.dosnackbar, url, requestOptions)
    },

    async good(item,file) {

      const formData  = new FormData();
      formData.append('_method', 'PATCH')
      formData.append('proof', file )

      let headers = await getHeaders()
      //headers['Content-Type'] = 'multipart/form-data; boundary="----fucked boundary"'

      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: formData
      };

      //patch payment_via_links/{payment_via_link}/success{token}
      let url = `${RestApiUrl}/${item.id}/success/1`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    async save(item, mode) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: mode === "new" ? 'POST' : 'PATCH',
        headers: headers,
        body: JSON.stringify(item)
      };

      let url = `${RestApiUrl}/${mode === "new" ? "" : item.id}`

      fetcher(this.dosnackbar, url, requestOptions)
    },


    fin(val, r = 2) {
      return Number.parseFloat(val).toFixed(r);
    },
  },

}
</script>
